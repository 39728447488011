import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
// Copy and paste this into your JavaScript code to initialize the Firebase SDK.
// You will also need to load the Firebase SDK.
// See https://firebase.google.com/docs/web/setup for more details.

firebase.initializeApp({
  "projectId": "insightplay-32dd3",
  "appId": "1:118434713082:web:963b05cae9baa03157a843",
  "storageBucket": "insightplay-32dd3.appspot.com",
  "locationId": "us-central",
  "apiKey": "AIzaSyAZ7vxXBqSQtLi8wFBnfrX2Sp0q9DQ2xk8",
  "authDomain": "insightplay-32dd3.firebaseapp.com",
  "messagingSenderId": "118434713082",
  "measurementId": "G-S2JG2WQFHG"
});

export const db = firebase.firestore();
export const storage = firebase.storage();

// eslint-disable-next-line no-restricted-globals
// if (location.hostname === 'localhost') {
//   db.useEmulator('localhost', 8080);
//   storage.useEmulator('localhost', 9199);
// }


export default firebase;