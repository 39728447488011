import { useEffect, useState } from 'react';

export default function HttpRequest() {
  const [fetchedData, setFetchedData] = useState([]);
  useEffect(() => {
    const getData = async () => {
      const response = await fetch(
        'https://jsonplaceholder.typicode.com/todos/1'
      );
      const data = await response.json();
      setFetchedData(data);
    };
    getData();
  }, []);

  console.log('data: ', fetchedData);

  return (
    <div>{fetchedData ? <h2>fetchedData: {fetchedData.title}</h2> : null}</div>
  );
}
