/* eslint-disable import/no-anonymous-default-export */
import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export default function Copyright() {
  const { t } = useTranslation();
  return (
    <Typography variant='caption' color={'text.main'}>
      © {new Date(). getFullYear()} InsightPlay, {t('all rights reserved')}
    </Typography>
  );
}
