/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable no-empty-pattern */
import React, { useState } from 'react';
import './video.css';
import ModalVideo from 'react-modal-video';

export default function Video({ videoId, backdropImageUrl }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <React.Fragment>
      <ModalVideo
        channel='youtube'
        autoplay
        isOpen={isOpen}
        videoId={videoId}
        onClose={() => setOpen(false)}
      />
      {videoId && (
        <div>
          <img
            className='video--backdrop with-video'
            src={`${backdropImageUrl}`}
            alt='Play'
            onClick={() => setOpen(true)}
          />
          <button className='video--play-button' onClick={() => setOpen(true)}>
            ▶ Play
          </button>
        </div>
      )}
      {!videoId && (
        <div>
          <img
            className='video--backdrop without-video'
            src={`${backdropImageUrl}`}
            alt='Play'
          />
        </div>
      )}
    </React.Fragment>
  );
}
