/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import Image from 'mui-image';
import { useTranslation } from 'react-i18next';

import './donate.css';

export default function Donate() {
  const { t } = useTranslation();
  return (
    <div className='donate'>
      <Typography variant='subtitle1'>{t('Donations')}</Typography>
      <Typography variant='caption'>
        <Grid container>
          <Grid className='pix' xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid>
              <Image src='/images/qrcode.png' width={'50%'} />
            </Grid>
            <Grid>Pix</Grid>
            <Grid>
              <Image src='/images/pix.jpg' width={'50%'} />
            </Grid>
          </Grid>
          <Grid className='paypal' xs={6} sm={6} md={6} lg={6} xl={6}>
            <Grid>
              <Image src='/images/qrcode.png' width={'50%'} />
            </Grid>
            <Grid>PayPal</Grid>
            <Grid>
              <Image
                src='/images/credit-card.png'
                width={'50%'}
                height={'50%'}
              />
            </Grid>
          </Grid>
        </Grid>
        {t('Summary Donations')}
      </Typography>
    </div>
  );
}
