import firebase from '../apis/firebase';

function loadFeatures() {
  try {
    const features = [
      'auth',
      'database',
      'firestore',
      'functions',
      'messaging',
      'storage',
      'analytics',
      'remoteConfig',
      'performance',
    ].filter((feature) => typeof firebase[feature] === 'function');
    return features;
  } catch (e) {
    console.error(e);
    return null;
  }
}

function HelloFirebase() {
  const loadedModules = loadFeatures();
  return (
    <div>
      <div id='message'>
        {loadedModules ? (
          <span>Firebase SDK loaded: {loadedModules.join(', ')} </span>
        ) : (
          <span className='error'>Firebase SDK error, check the console</span>
        )}
      </div>
    </div>
  );
}

export default HelloFirebase;
