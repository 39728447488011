import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import MenuBookIcon from '@mui/icons-material/MenuBook';

import './popup.css';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import DOMPurify from 'dompurify';
import { IconButton } from '@mui/material';

export default function Popup({title, icon, content}) {
    const { t } = useTranslation();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    let safeContentHTML = content;

    useEffect(() => {
        safeContentHTML = DOMPurify.sanitize(content);
    }, []);

    return (<>
        <IconButton aria-label='MenuBook' color='primary' onClick={handleOpen}>
            {icon}
            <Typography className='page--icon-label'>
            {title}
            </Typography>
        </IconButton>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
        >
          <Box className='popup'>
          
            <Typography id="modal-title" variant="h6" component="h2" color='primary'>
            {icon} {title}
            </Typography>
            <Typography id="modal-description" sx={{ mt: 2 }} color='secondary'>
                {safeContentHTML && <div dangerouslySetInnerHTML={{__html: safeContentHTML}}></div>}
            </Typography>
            <Button 
                variant='outlined' 
                color='secondary' 
                onClick={handleClose}
                sx={{position: 'inherit',  bottom: '10px', left: '45%'}}
            >
                <Typography variant='caption'>{t('Close')}</Typography>
            </Button>
            </Box>  
        </Modal>
    </>);
}