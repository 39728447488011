import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import './i18n';
import NotFound from './site/notfound';
import Layout from './site/layout';
import Sandbox from './sandbox/main';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path='/:pageURL' element={<Layout />} />
        <Route path="*" element={<Navigate to='/home' />} />
        <Route path="/sandbox" element={<Sandbox />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

