/* eslint-disable import/no-anonymous-default-export */
import { Typography } from '@mui/material';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import './language.css';

export default function Language({ page }) {
  const { t, i18n } = useTranslation();
  const handleLanguageChange = async (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <Box className='language'>
      <Typography variant='subtitle1'>{t('Language')}</Typography>
      <Stack
        direction={{
          xs: 'row',
          sm: 'column',
          md: 'column',
          lg: 'column',
          xl: 'column',
        }}
      >
        <ListItemButton
          onClick={(event) => handleLanguageChange('en')}
          component='a'
        >
          <ListItemText>English</ListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={(event) => handleLanguageChange('pt')}
          component='a'
        >
          <ListItemText>Português</ListItemText>
        </ListItemButton>
        <ListItemButton
          onClick={(event) => handleLanguageChange('es')}
          component='a'
        >
          <ListItemText>Español</ListItemText>
        </ListItemButton>
      </Stack>
    </Box>
  );
}
