/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import Grid from '@mui/material/Grid';

import Language from './language';
import About from './about';
import Donate from './donate';
import Copyright from './copyright';

export default function Footer({ data }) {
  const page = data;
  return (
    <Grid className='footer' container spacing={0.5}>
      <Grid item xs={12} sm={3} md={2} lg={1} xl={1}>
        <Language page={page} />
      </Grid>
      <Grid item xs={12} sm={4} md={6} lg={4} xl={4}>
        <About />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
        &nbsp;
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
        {page.donate && <Donate />}
      </Grid>

      <Grid item textAlign={'center'} xs={12} sm={12} md={12} lg={12} xl={12}>
        <Copyright />
      </Grid>
    </Grid>
  );
}
