/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';

import dompurify from 'dompurify';

import './about.css';

export default function About() {
  const { t } = useTranslation();

  const sanitizer = dompurify.sanitize;

  return (
    <Box className='about'>
      <Typography variant='subtitle1'>{t('About Us')}</Typography>
      <Typography
        variant='caption'
        dangerouslySetInnerHTML={{ __html: sanitizer(t('Summary About Us')) }}
      ></Typography>
      <br /> <br />
      <Button variant='outlined' color='text'>
        <Typography variant='caption'>{t('Contact us')}</Typography>
      </Button>
    </Box>
  );
}
