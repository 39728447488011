import HttpRequest from './http-request';
import './main.css';
import HelloTheme from './hello-theme';
import HelloFirestore from './hello-firestore';
import HelloFirebase from './hello-firebase';
import HelloStorage from './hello-storage';

export default function Sandbox() {
  return (
    <div>
      <h1>Sandbox - insightplay</h1>
      <div className='card'>
        <h3>Theme</h3>
        <div className='content'>
          <HelloTheme></HelloTheme>
        </div>
      </div>

      <div className='card'>
        <h3>Http Request</h3>
        <div className='content'>
          <HttpRequest></HttpRequest>
        </div>
      </div>

      <div className='card'>
        <h3>Hello Firebase!</h3>
        <div className='content'>
          <HelloFirebase></HelloFirebase>
        </div>
        <div className='ref'>
          References:
          <ul>
            <li>
              <a
                target='_blank'
                href='https://firebase.google.com/docs/hosting?hl=pt-br'
                rel='noreferrer'
              >
                Firebase Hosting
              </a>
            </li>
            <li>
              <a
                target='_blank'
                href='https://blog.logrocket.com/using-firebase-emulator-suite-and-react-for-local-first-development/'
                rel='noreferrer'
              >
                Using Firebase Emulator Suite and React for local-first
                development
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className='card'>
        <h3>Hello Firestore</h3>
        <div className='content'>
          <HelloFirestore></HelloFirestore>
        </div>
      </div>

      <div className='card'>
        <h3>Hello Storage</h3>
        <div className='content'>
          <HelloStorage></HelloStorage>
        </div>
      </div>
    </div>
  );
}
