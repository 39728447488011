import {
  collection,
  addDoc,
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  query,
  orderBy,
  getDocs,
  onSnapshot,
} from 'firebase/firestore';

import { db } from './../apis/firebase';
import { useState, useEffect } from 'react';
export default function HelloFirestore() {
  const [id, setId] = useState(null);
  const [message, setMessage] = useState(null);
  const [messages, setMessages] = useState([]);
  const [snapshotMessages, setSnapshotMessages] = useState([]);

  const createMessage = async (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();
    try {
      // Read the form data
      const form = e.target;
      const formData = new FormData(form);

      // Or you can work with it as a plain object:
      const formJson = Object.fromEntries(formData.entries());

      // const db = firegetFirestore();
      const resultSet = await addDoc(collection(db, 'message'), formJson);
      setId(resultSet.id);
    } catch (err) {
      alert(err);
    }
  };

  const readMessage = async (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();
    try {
      // Read the form data
      const form = e.target;
      const formData = new FormData(form);

      // Or you can work with it as a plain object:
      const formJson = Object.fromEntries(formData.entries());

      // const db = getFirestore();
      const docRef = doc(db, 'message', formJson.id);
      const docSnap = await getDoc(docRef);
      setMessage(docSnap.data().message);
    } catch (err) {
      alert(err);
    }
  };

  const updateMessage = async (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();
    try {
      // Read the form data
      const form = e.target;
      const formData = new FormData(form);

      // Or you can work with it as a plain object:
      const formJson = Object.fromEntries(formData.entries());

      // const db = getFirestore();
      const docRef = doc(db, 'message', formJson.id);
      const docSnap = await updateDoc(docRef, { message: formJson.message });
    } catch (err) {
      alert(err);
    }
  };

  const deleteMessage = async (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();
    try {
      // Read the form data
      const form = e.target;
      const formData = new FormData(form);

      // Or you can work with it as a plain object:
      const formJson = Object.fromEntries(formData.entries());

      // const db = getFirestore();
      const docRef = doc(db, 'message', formJson.id);
      const docSnap = await deleteDoc(docRef);
    } catch (err) {
      alert(err);
    }
  };

  const listMessages = async (e) => {
    // Prevent the browser from reloading the page
    e.preventDefault();
    try {
      // const db = getFirestore();
      const q = query(collection(db, 'message'));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        console.log(doc.id, ' => ', doc.data());
      });
      setMessages(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          message: doc.data().message,
        }))
      );
    } catch (err) {
      alert(err);
    }
  };

  /* function to get all tasks from firestore in realtime */
  useEffect(() => {
    // const db = getFirestore();
    const q = query(collection(db, 'message'));
    onSnapshot(q, (querySnapshot) => {
      setSnapshotMessages(
        querySnapshot.docs.map((doc) => ({
          id: doc.id,
          message: doc.data().message,
        }))
      );
    });
  }, []);

  return (
    <div>
      <div>
        <h4>Create Hello</h4>
        <form onSubmit={createMessage}>
          <input
            name='message'
            placeholder='Message'
            autoComplete='off'
          ></input>
          <button type='submit'>Create</button>
        </form>
        <p>id: {id}</p>
      </div>
      <div>
        <h4>Read Hello</h4>
        <form onSubmit={readMessage}>
          <input name='id' placeholder='id' autoComplete='off'></input>
          <button type='submit'>Read</button>
        </form>
        <p>Message: {message}</p>
      </div>
      <div>
        <h4>Update Hello</h4>
        <form onSubmit={updateMessage}>
          <input name='id' placeholder='id' autoComplete='off'></input>
          <input
            name='message'
            placeholder='message'
            autoComplete='off'
          ></input>
          <button type='submit'>Update</button>
        </form>
        <p>Message: {message}</p>
      </div>
      <div>
        <h4>Delete Hello</h4>
        <form onSubmit={deleteMessage}>
          <input name='id' placeholder='id' autoComplete='off'></input>
          <button type='submit'>Delete</button>
        </form>
      </div>

      <div>
        <h4>Simple List Hello</h4>
        <form onSubmit={listMessages}>
          <button type='submit'>List</button>
        </form>
        <ul>
          {messages.map((message) => (
            <li key={message.id}>{message.message}</li>
          ))}
        </ul>
      </div>

      <div>
        <h4>Snapshot List Hello</h4>
        <ul>
          {snapshotMessages.map((snapshotMessage) => (
            <li key={snapshotMessage.id}>{snapshotMessage.message}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}
