/* eslint-disable import/no-anonymous-default-export */
import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ShareIcon from '@mui/icons-material/Share';
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import DOMPurify from 'dompurify';

import Video from './video';
import './page.css';
import Popup from '../site/popup';

export default function Page({ data }) {
  const [page, setPage] = useState({});

  let safeSynopsisHTML = data.synopsis;
  
  useEffect(() => {
    setPage(data);
    document.title = 'InsightPlay - ' + data.title;
    safeSynopsisHTML = DOMPurify.sanitize(data.synopsis);
  }, []);
  const { t } = useTranslation();
  return (
    <Grid container spacing={1} className='page'>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Link reloadDocument to={`/${page.parent?.pageURL}`} color='primary'>
          <Typography variant='h6' color={'primary'}>
            {page.parent?.title}
          </Typography>
        </Link>
      </Grid>
      {page && (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} flexDirection='row'>
          <Typography variant='h4' color={'primary'}>
            {page.title}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Typography color='secondary' className='page--synopsis'>
          {safeSynopsisHTML && <div dangerouslySetInnerHTML={{__html: safeSynopsisHTML}}></div>}
        </Typography>
        {page.watched && (
          <IconButton aria-label='check' color='primary'>
            <CheckIcon />
            <Typography className='page--icon-label'>{t('Watched')}</Typography>
          </IconButton>
        )}

        {page.epilogue && (
          <Popup title={t('Epilogue')} icon={<MenuBookIcon />} content={page.epilogue}/>
        )}
        {page.prologue && (
          <Popup title={t('Prologue')} icon={<AutoStoriesOutlinedIcon />} content={page.prologue}/>
        )}
        {page.pageURL &&  false && (
          <IconButton aria-label='Share' color='primary'>
            <ShareIcon />
            <Typography className='page--icon-label'>{t('Share')}</Typography>
          </IconButton>
        )}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
        <Video
          videoId={page.videoId}
          backdropImageUrl={page.image?.src}
        ></Video>
      </Grid>
    </Grid>
  );
}
