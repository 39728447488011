import { createTheme, ThemeProvider } from '@mui/material/styles';
import { cyan, green, grey, pink, purple, yellow } from '@mui/material/colors';
import { Box, Grid, Typography } from '@mui/material';

const insightPlayTheme = createTheme({
  palette: {
    primary: { main: cyan.A400 },
    secondary: { main: yellow.A400 },
    text: { main: grey.A400 },
    editor: { main: purple.A400 },
  },
});

export default function HelloTheme() {
  return (
    <ThemeProvider theme={insightPlayTheme}>
      <Grid>
        <Typography color='primary'>primary</Typography>
        <Typography color='secondary'>secondary</Typography>
        <Typography color='text.main'>text.main</Typography>
        <Typography color='editor.main'>editor.main</Typography>
      </Grid>
    </ThemeProvider>
  );
}
