/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import Grid from '@mui/material/Grid';

import './logo.css';

export default function Logo() {
  return (
    <Grid container spacing={0.5}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className='header--logo'>
        <div>
          <a href='/'>
            <img src='/logo.png' alt='insightplay' />
          </a>
        </div>
        <div className='line'></div>
      </Grid>
    </Grid>
  );
}
