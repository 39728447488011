import { useState } from 'react';
import { storage } from './../apis/firebase';
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
  listAll,
} from 'firebase/storage';

export default function HelloStorage() {
  const [file, setFile] = useState('');
  const [percent, setPercent] = useState(0);
  const [imageUrl, setImageUrl] = useState('');
  const [allImages, setAllImages] = useState([]);

  function handleUpload() {
    if (!file) {
      alert('Please choose a file first!');
    }
    const storageRef = ref(storage, `/files/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const percent = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        // update progress
        setPercent(percent);
      },
      (err) => console.log(err),
      () => {
        // download url
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          console.log(url);
          setImageUrl(url);
        });
      }
    );
  }

  function deleteFile() {
    if (!file) {
      alert('Please choose a file first!');
    }
    const storageRef = ref(storage, `/files/${file.name}`);
    // Delete the file
    deleteObject(storageRef)
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
        console.error(error);
      });
  }

  async function listImages() {
    // Create a reference under which you want to list
    const listRef = ref(storage, 'files/');

    // Find all the prefixes and items.

    const res = await listAll(listRef);
    const urlPromises = await res.items.map((itemRef) =>
      getDownloadURL(itemRef)
    );
    const urls = await Promise.all(urlPromises);
    console.log(urls);
    setAllImages(urls);

    // listAll(listRef)
    //   .then(async (res) => {
    //     res.prefixes.forEach((folderRef) => {
    //       console.log(folderRef);
    //       // All the prefixes under listRef.
    //       // You may call listAll() recursively on them.
    //     });

    //     const urlPromisses = await res.items.map((itemRef) => {
    //       return getDownloadURL(itemRef);
    //     });

    //     console.log(urlPromisses);
    //     setAllImages(urlPromisses);
    //     // setAllFiles(
    //     //   res.items.map(async (itemRef) => {
    //     //     return await getDownloadURL(itemRef);
    //     //   })
    //     // );
    //     // res.items.forEach((itemRef) => {
    //     //   getDownloadURL(itemRef).then((url) => {
    //     //     console.log(url);
    //     //   });
    //     // });
    //     // res.items.forEach((itemRef) => {
    //     //   getDownloadURL(itemRef).then((url) => {
    //     //     console.log(url);
    //     //   });
    //     // });
    //     // setAllFiles(
    //     //   res.items.forEach((itemRef) => {
    //     //     getDownloadURL(itemRef).then((url) => {
    //     //       console.log(url);
    //     //     });
    //     //   })
    //     // );
    //   })
    //   .catch((error) => {
    //     console.error(error);
    //     // Uh-oh, an error occurred!
    //   });
  }

  // Handles input change event and updates state
  function handleChange(event) {
    setFile(event.target.files[0]);
  }
  return (
    <div>
      <div>
        <h4>Hello Upload</h4>
        <div>
          <input type='file' accept='image/*' onChange={handleChange} />
          <button onClick={handleUpload}>Upload to Firebase</button>
          <p>{percent} "% done"</p>
        </div>
      </div>
      <div>
        <h4>Hello Image</h4>
        <img alt='' src={imageUrl}></img>
      </div>
      <div>
        <h4>Hello Download</h4>
        <a target='blank' href={imageUrl}>
          {imageUrl}
        </a>
      </div>
      <div>
        <h4>Hello Delete</h4>
        <button onClick={deleteFile}>Delete File</button>
      </div>
      <div>
        <h4>Image List</h4>
        <button onClick={listImages}>List all files</button>
        <ul>
          {allImages.map((image) => (
            <li key={image}>{image}</li>
          ))}
        </ul>
        {allImages.map((image) => (
          <img alt='' key={image} src={image} width={160} height={90}></img>
        ))}
      </div>
    </div>
  );
}
