import React, { useState, useEffect } from 'react';
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useParams,
  useNavigate,
} from "react-router-dom";

import Box from '@mui/material/Box';
import { cyan, grey, yellow } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { Grid } from '@mui/material';
import Page from '../content/page';
import Related from '../content/related';
import Footer from './footer';
import Logo from './logo';

import './layout.css';
import { getPage } from '../apis/insightplay';
import { useTranslation } from 'react-i18next';

const theme = createTheme({
  palette: {
    primary: {
      main: cyan['A200'],
    },
    secondary: {
      main: yellow['A700'],
    },
    text: {
      main: grey['400'],
      secondary: yellow['A700'],
    },
  },
});

export default function Layout() {
  const { pageURL } = useParams();
  const [page, setPage] = useState([]);
  const navigate = useNavigate();

  const { t, i18n } = useTranslation();

  useEffect(async() => {
    try {
      const fetchedPage = await getPage(pageURL);
      setPage([fetchedPage]);
    } catch(error) {
      if(error == 404) {
        switch(i18n.language) {
          case 'en':
            navigate('/404-page-not-found');
            break;
          case 'pt':
            navigate('/404-pagina-nao-encontrada');
            break;
          case 'es':
            navigate('/404-pagina-no-encontrada');
        }
        window.location.reload(false); //Refresh da página
      }
    }
  }, []);
 
  return (<>
    <ThemeProvider theme={theme}>
      <Box>
        <Logo></Logo>
       {
      page && page.map(pg=>{
        return(
          <Box
            key={pg.id}
            className='backdrop'
            style={{
              backgroundImage: `url(${pg.image?.src})`,
            }}
          >
            <Box className='backdrop--vertical-shadow'>
              <Box className='backdrop--horizontal-shadow'>
                <Grid container spacing={1} paddingLeft='4px' paddingRight='4px'>
                  <Grid item xs={12}>
                     <Page data={pg} />
                  </Grid>
                  <Grid item xs={12}>
                    <Related data={pg.related} />
                  </Grid>
                </Grid>
                <Footer data={pg}></Footer>
              </Box>
           </Box>
          </Box>
        )
      })
    }
      </Box>
    </ThemeProvider>
  </>);
}
