import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

const resources = {
  en: {
    translation: {
      'all rights reserved': 'all rights reserved',
      'Coming soon': 'Coming soon',
      'Theme': 'Theme',
      'Serie': 'Serie',
      'Episode': 'Episode',
      'Watched': 'Watched',
      'Epilogue': 'Epilogue',
      'Prologue': 'Prologue',
      'Share': 'Share',
      'Language': 'Language',
      'About Us': 'About Us',
      'Summary About Us': 'InsightPlay.org / Discovering the Humans of Planet Aquaria...<br/>' +
        'it is not a temple of Personas, but of ideas...<br/>' +
        'And that\'s why \'s a unique opportunity... to create Perception Waves and Collaborator Groups that don\'t depend on anyone or myths...<br/>' +
        'but the effectiveness of words...<br/>' +
        'that reflect Perceptions of Reality... Thus unveiling the truth... until Lambs become Lions...<br/>' +
        'And all become Alphas Reborn... Not by domination of herds,<br/>' +
        'but for the expansion of the Consciousness of all of us...<br/>' +
        'This being yes,<br/>' +
        'the biggest and best of all challenges...',
      'Contact us': 'Contact us',
      'Donations': 'Donations',
      'Summary Donations': 'With our Deepest Appreciation... Your donations will strengthen the production and reach of this Message.',
      'Close': 'Close'
    }
  },
  pt: {
    translation: {
      'all rights reserved': 'todos os direitos reservados',
      'Coming soon': 'Em breve',
      'Theme': 'Tema',
      'Serie': 'Série',
      'Episode': 'Episódio',
      'Watched': 'Assistido',
      'Epilogue': 'Epílogo',
      'Prologue': 'Prólogo',
      'Share': 'Compartilhar',
      'Language': 'Idioma',
      'About Us': 'Quem Somos',
      'Summary About Us': 'A InsightPlay.org / Descobrindo os Humanos do Planeta Aquária... <br/>' +
        'não é um templo de Personas, mas de ideias...<br/>' +
        'E é por isso uma oportunidade única... de se criar Ondas de Percepção e Grupos de Colaboradores que não dependam de alguém ou de mitos...<br />' +
        'mas da eficácia de palavras... <br />' +
        'que reflitam Percepções da Realidade... Assim desvendando a verdade... até que Cordeiros se tornem Leões... <br />' +
        'E todos se tornem Alfas Renascidos... Não pelo domínio de rebanhos, <br />' +
        'mas pela expansão da Consciência de todos nós...<br />' +
        'Sendo este sim, <br />' +
        'o maior e melhor de todos os desafios...',
      'Contact us': 'Entre em contato conosco',
      'Donations': 'Doações',
      'Summary Donations': 'Com nosso mais Profundo Apreço... Suas doações irão fortalecer a produção e alcance desta Mensagem.',
      'Close': 'Fechar'
    }
  },
  es: {
    translation: {
      'all rights reserved': 'todos los derechos reservados',
      'Coming soon': 'Próximamente',
      'Theme': 'Tema',
      'Serie': 'Serie',
      'Episode': 'Episodio',
      'Watched': 'Observó',
      'Epilogue': 'Epílogo',
      'Prologue': 'Prólogo',
      'Share': 'Cuota',
      'Language': 'Idioma',
      'About Us': 'Quienes Somos',
      'Summary About Us': 'InsightPlay.org / Descubriendo a los Humanos del Planeta Acuario...<br/>' +
        'no es un templo de Personas, sino de ideas...<br/>' +
        'Y por eso es una oportunidad única... de crear Olas de Percepción y Grupos Colaboradores que no dependan de nadie ni de mitos...<br/>' +
        'pero la eficacia de las palabras...<br/>' +
        'que reflejan Percepciones de la Realidad... Desvelando así la verdad... hasta que los Corderos se conviertan en Leones...<br/>' +
        'Y todos se convierten en Alphas Reborn... No por dominación de rebaños,<br/>' +
        'sino para la expansión de la Conciencia de todos nosotros...<br/>' +
        'siendo esto si,<br/>' +
        'el mayor y mejor de todos los retos...',
      'Contact us': 'Contáctenos',
      'Donations': 'Donaciones',
      'Summary Donations': 'Con Nuestro Más Profundo Agradecimiento... Sus donaciones fortalecerán la producción y alcance de este Mensaje.',
      'Close': 'Cerrar'
    }
  }
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: {
      order: ['localStorage', 'navigator'],
      caches: ['localStorage']
    },
    resources,
    keySeparator: false,
    fallbackLng: 'en',
  });

export default i18n;