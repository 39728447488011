import {
  collection,
  addDoc,
  getFirestore,
  doc,
  getDoc,
  updateDoc,
  deleteDoc,
  query,
  where,
  orderBy,
  getDocs,
  onSnapshot,
  Timestamp,
} from 'firebase/firestore';
import { db } from './firebase';

export function getPage2() {
  return {
    "id": "descobrindo-os-humanos-do-planeta-aquaria",
    "urlSlug": "descobrindo-os-humanos-do-planeta-aquaria",
    "title": "Descobrindo os Humanos do Planeta Aquária",
    "backdropImageUrl": "/images/dh-backdrop.png",
    "synopsis": "Seja Bem-vindo a nossa Missão ao Planeta Aquária... onde o Comandante da Nave Estelar Galáctica lidera seus jovens a refletirem sobre os   erros e acertos da Civilização Aquariana.Como convidado, você irá embaralhar seus pensamentos com as percepções de Seres de uma Civilização Galáctica.E irá muito além das tramas típicas de ficção. Já que, os Insights sem precedentes aqui revelados, sobre Deus, Exploração Espacial, Evolução da Consciência e Educação, são prova cabal... de Primeiro Contato.",
    "videoId": "mkggXE5e2yk",
    "parent": {
      "title": "Ciência",
      "urlSlug": "ciencia"
    },
    "related": [
      {
        "title": "Episódio 1 - Plano da Missão",
        "backdropImageUrl": "/images/e1-backdrop.png",
        "urlSlug": "ep1"
      },
      {
        "title": "Episódio 2 – A Grande Jornada da Consciência",
        "backdropImageUrl": "/images/e2-backdrop.png",
        "urlSlug": "ep2"
      },
      {
        "title": "Episódio 3 – Em Busca de Vida",
        "backdropImageUrl": "/images/e3-backdrop.png",
        "urlSlug": "ep3"
      },
      {
        "title": "Episódio 4 – Exploração Espacial",
        "backdropImageUrl": "/images/e4-backdrop.png",
        "urlSlug": "ep4"
      }
    ]
  }
}

export const getPage = async (pageURL) => {
  const querySnapshot = await db.collection('pages')
    .where('pageURL', '==', pageURL)
    .get();

  let page;
  if(!querySnapshot.empty) {
    page = querySnapshot.docs[0].data();
    const today = new Date().getTime();
    const publishedAt = page.publishedAt.toMillis();
    if (publishedAt > today) {
      page = undefined;
    }
  }
  if(!page) {
    throw 404;
  }

  if(page.parentId && page.id !== page.parentId) {
    const docRef = doc(db, 'pages', page.parentId);
    try {
      const docSnap = await getDoc(docRef);
      page.parent = docSnap.data();
    } catch(error) {
        console.error(error);
    }
  }

  if(page?.childPagesId && page.childPagesId.length > 0) {
      const related = [];
      for(let i=0; i < page.childPagesId.length; i++) {
        const id = page.childPagesId[i];
        const childPage = await getPageById(id);
        if(childPage) {
          related.push(childPage);
        }
      }
      page.related = related;
  }
  return page;
}

export const getPageById = async (pageId) => {
  const docRef = doc(db, 'pages', pageId);
  try {
      const docSnap = await getDoc(docRef);
      const page = docSnap.data();
      const today = new Date().getTime();
      const publishedAt = page.publishedAt?.toMillis();
      if(!publishedAt) {
        return undefined;
      }
      if (publishedAt > today) {
        return undefined;
      }
      return page;
    } catch(error) {
        console.error(error);
    }
}
