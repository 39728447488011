/* eslint-disable import/no-anonymous-default-export */
import React from 'react';
import Grid from '@mui/material/Grid';
import { Link } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import Image from 'mui-image';

import './related.css';

export default function Related({ data }) {
  const pages = data;
  return (
    <Grid
      container
      spacing={0.5}
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      {pages?.map((page) => {
        return (
          <Grid key={page.pageURL} className='related' xs={6} sm={4} lg={2}>
            <Link reloadDocument to={`/${page.pageURL}`}>
              <Image
                className='related--thumbnails'
                src={page.image?.src}
              />
              <Typography className='related--title' color='primary'>
                {page.title}

                {page.watched && <CheckIcon />}
              </Typography>
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
}
